import React from 'react';
import styled from 'styled-components';

const Loader = () => {
    return <Root>Loading...</Root>;
};

const Root = styled.div`
    font-size: 10px;
    /* margin: 50px auto; */
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: ${p => p.theme.colors.spinner};
    background: -moz-linear-gradient(
        left,
        ${p => p.theme.colors.spinner} 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -webkit-linear-gradient(
        left,
        ${p => p.theme.colors.spinner} 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -o-linear-gradient(
        left,
        ${p => p.theme.colors.spinner} 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -ms-linear-gradient(
        left,
        ${p => p.theme.colors.spinner} 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: linear-gradient(
        to right,
        ${p => p.theme.colors.spinner} 10%,
        rgba(255, 255, 255, 0) 42%
    );
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    &:before {
        width: 50%;
        height: 50%;
        background: ${p => p.theme.colors.spinner};
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
    }

    &:after {
        background: ${p => p.theme.colors.background};
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    @keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`;

export default Loader;
